<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container pad-top--xxl">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-5 pad-top--xxl">
          <h1 class=" text-white large-heading pad-bottom">Log in</h1>
          <p class="text-white">
            This website is for registered delegates only. Please enter the email address you registered with to gain access.
          </p>
          <div class="grid-x grid-padding-x pad-top--xl">
            <div class="cell small-12 medium-4">
              <label class="text-white">Email address</label>
            </div>
            <div class="cell small-12 medium-8">
              <input v-model="user.email" type="email" class="bg--transparent">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-4">
              <label class="text-white">Password</label>
            </div>
            <div class="cell small-12 medium-8">
              <input v-model="user.password" type="password" class="bg--transparent">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-4">
              &nbsp;
            </div>
            <div class="cell small-12 medium-8">
              <button @click="login" class="button">Log in</button>
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-8 medium-offset-4">
              <router-link to="/forgot-password">Forgot password</router-link>
            </div>
          </div>
        </div>
        <div class="cell show-for-medium medium-1 border-right border-white"></div>
        <div class="cell show-for-medium medium-1"></div>
        <div class="cell small-12 medium-5 pad-top--xxl">
          <h2 class="text-white large-heading padd-bottom">Register</h2>
          <p class="text-white">
            Register to gain access to the<br />Ipsen Prospective Consulant Academy<br />Programme.
          </p>
          <div class="grid-x grid-padding-x pad-top--xl">
            <div class="cell small-12 medium-4">
              <label class="text-white">Email address</label>
            </div>
            <div class="cell small-12 medium-8">
              <input v-model="registree.email" type="email" class="bg--transparent">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-4">
              <label class="text-white">Password</label>
            </div>
            <div class="cell small-12 medium-8">
              <input v-model="registree.password" type="password" class="bg--transparent">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-4">
              <label class="text-white">Check password</label>
            </div>
            <div class="cell small-12 medium-8">
              <input v-model="registree.checkpassword" type="password" class="bg--transparent">
            </div>
          </div>
          <div class="grid-x grid-padding-x">
            <div class="cell small-12 medium-4">
              &nbsp;
            </div>
            <div class="cell small-12 medium-8">
              <button @click="register" class="button">Register</button>
              <p class="text-white" v-if="message.length > 0">
                {{ message }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Localbase from 'localbase';
import axios from '../axios';

export default {
  name: 'Login',
  data() {
    return {
      message: '',
      user: {
        email: '',
        password: '',
      },
      registree: {
        email: '',
        password: '',
        checkpassword: '',
      },
    };
  },
  methods: {
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          // test if these are still valid
          const testToken = users[0].token;
          const userid = users[0].id;
          axios.post(`/users/testCredentials.json?token=${testToken}&user=${userid}`)
            .then((response) => {
              this.$store.commit('moduleOneAttempts', response.data.moduleOneAttempts);
              this.$store.commit('moduleTwoAttempts', response.data.moduleTwoAttempts);
              this.$store.commit('moduleOnePassed', response.data.moduleOnePassed);
              this.$store.commit('moduleTwoPassed', response.data.moduleTwoPassed);
              this.$router.push('/home');
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch('unsetCredentials');
              this.hide = false;
            });
        } else {
          this.hide = false;
        }
      });
    },
    login() {
      const postData = {};
      postData.email = this.user.email;
      postData.password = this.user.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$store.commit('moduleOneAttempts', response.data.moduleOneAttempts);
          this.$store.commit('moduleTwoAttempts', response.data.moduleTwoAttempts);
          this.$store.commit('moduleOnePassed', response.data.moduleOnePassed);
          this.$store.commit('moduleTwoPassed', response.data.moduleTwoPassed);
          this.$router.push('/home');
        })
        .catch(() => {
          this.failed = true;
        });
    },
    register() {
      if (this.registree.password === this.registree.checkpassword) {
        const postData = {};
        postData.email = this.registree.email;
        postData.password = this.registree.password;
        axios.post('/users/add.json', postData)
          .then((response) => {
            this.$store.dispatch('setCredentials', response.data);
            this.$router.push('/home');
          })
          .catch(() => {
            this.failed = true;
          });
      } else {
        this.message = 'Those passwords do not match.';
      }
    },
  },
  mounted() {
    this.checkForCredentials();
  },
}
</script>
